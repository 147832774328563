/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // remove hash from url
        var hash = window.location.hash.replace("#", "");

        // onClick event toggling between search and cross icon
        $("li.search-button a").on('click touchstart', function(){
          $("li.search-button").toggleClass("search_cross");
          return false;
        });
        // Mobile onClick event
        $(".mobile_search-button").on('click touchstart', function(){
          $(".mobile_search-button").toggleClass("mobile_search-cross");
          return false;
        });
        // Mobile onClick event 2nd
        $(".mobile_search-button").on('click touchstart', function(){
          $(".searchouter").toggleClass('active_search');
          $("div.tweet-this-container").toggle('show');
          return false;
        });
        // onClick event for search drop down menu
        $("li.search-button a").on('click touchstart', function(){
          $(".searchouter").toggleClass('active_search');
          $("div.tweet-this-container").toggle('show');
        });

        $('.mobile-header-button').on('click touchstart', function() {
          if ($('html').hasClass('nav')) {
            $('html').removeClass('nav');
          } else {
            $('html').addClass('nav');
          }
        });

        $('#your-solutions-btn').on('click touchstart', function(){
          var scrollOffset = $('header.banner').height();
          if ( $('.mobile-header').is(':visible') ) {
            scrollOffset = $('.mobile-header').height();
          }
          $('html, body, .wrap').animate({
            scrollTop: $("#your-solutions").offset().top - scrollOffset
          }, 1000);
        });

        $('#our-products-btn').on('click touchstart', function(){
          var scrollOffset = $('header.banner').height();
          if ( $('.mobile-header').is(':visible') ) {
            scrollOffset = $('.mobile-header').height();
          }
          $('html, body, .wrap').animate({
            scrollTop: $("#our-products").offset().top - scrollOffset
          }, 1000);
        });

        $('#contact-form-btn').on('click touchstart', function(){
          var scrollOffset = $('header.banner').height();
          if ( $('.mobile-header').is(':visible') ) {
            scrollOffset = $('.mobile-header').height();
          }
          $('html, body, .wrap').animate({
            scrollTop: $("#contact-form").offset().top-200
          }, 1000);
        });

        $('#talk-to-us-btn').on('click touchstart', function(){
          var scrollOffset = $('header.banner').height();
          if ( $('.mobile-header').is(':visible') ) {
            scrollOffset = $('.mobile-header').height();
          }
          $('html, body, .wrap').animate({
            scrollTop: $("#talk-to-us").offset().top - scrollOffset
          }, 1000);
        });

        var numOfPages = $('.learnBlocks li').length;
        var numToShow = 9;

        if (numToShow < numOfPages) {
          $('.learn_loadMore').show();
        }
        function showMore () {
            $('.learnBlocks li').hide().slice(0, numToShow).show();
            if (numToShow >= numOfPages) {
              $('.learn_loadMore').hide();
            }
        }

        $('#work-list .btn-more').on('click', function () {
          numToShow = numToShow+3;
          showMore();
          return false;
        });

        $('.imageBlocks.imageBlocks--alt li').on('click touchstart', function(){
          var link = $(this).find('a').attr('href');
          if ( link.length > 0 ) {
            window.location = link;
          }
        });

      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
          $('a[href*=#]').click(function () {
            var hash = $(this).attr('href');
            var offset = $(hash).offset();
            $('html, body').animate({
              scrollTop: offset.top-200
            }, 800);
            window.location.hash = '#' + hash;
            return false;
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.show--more').on('click touchstart', function(){
          var scrollOffset = $('header.banner').height();
          if ( $('.mobile-header').is(':visible') ) {
            scrollOffset = $('.mobile-header').height();
          }
          $('html, body, .wrap').animate({
            scrollTop: $("#our-products").offset().top - scrollOffset
          }, 800);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'learn': {
      init: function() {
        $("#learn-form").find('input[type=submit]').hide();
        $("#learn-form").on('change', function(){
          $(this).submit();
        });
      }
    },
    'contact': {
      init: function() {

        if ( $(".validation_error").width() !== null || $("#gform_confirmation_message_1").width() !== null ) {
          var scrollOffset = $('header.banner').height();
          if ( $('.mobile-header').is(':visible') ) {
            scrollOffset = $('.mobile-header').height();
          }
          $('html, body, .wrap').animate({
            scrollTop: $("#contact-form").offset().top - scrollOffset
          }, 500);
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
